import React from "react"

import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const query = graphql`
  query {
    allSanityBlog {
      edges {
        node {
          title
          description
          slug {
            current
          }
        }
      }
    }
  }
`

const ArticleList = () => (
  <StaticQuery
    query={query}
    render={data => (
      <ul>
        {data.allSanityBlog.edges.map(article => (
          <li key={article.node.id}>
            <Link to={`/blog/${article.node.slug.current}`}>
              {article.node.title}
            </Link>
          </li>
        ))}
      </ul>
    )}
  />
)

export default ArticleList
