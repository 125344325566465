import React from "react"
import Layout from "../../components/layouts/Layout"
import BlogList from "../../components/BlogList"
import SEO from "../../components/Seo"

const BlogIndex = () => {
  return (
    <Layout>
      <SEO title="Blog" description="Wine and Cider Blog" />
      <div className="prose mx-auto">
        <h1>Blog</h1>
        <BlogList />
      </div>
    </Layout>
  )
}

export default BlogIndex
